import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {Spinner, SpinnerDispatchProps, SpinnerStateProps} from "./Spinner";
import {State} from "../../store/rootReducers";
import {selectSpinnerStatus} from "../../selectors/popup";

const mapStateToProps: MapStateToProps<SpinnerStateProps,
    {},
    State> = state => {
    const spinnerStatus = selectSpinnerStatus(state);

    return {
        spinnerStatus: spinnerStatus > 0
  };
};

const dispatchProps: MapDispatchToProps<SpinnerDispatchProps, {}> = {
};

export const SpinnerConnected = connect(
  mapStateToProps,
  dispatchProps
)(Spinner);
