import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {PopupDialog, PopupDialogDispatchProps, PopupDialogStateProps} from "./PopupDialog";
import {State} from "../../store/rootReducers";
import {selectPopup} from "../../selectors/popup";
import {popupDelete} from "../../ducks/popup";
import {setComment, updateTechReportText} from "../../ducks/upload";
import {selectComment, selectTechReportText} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<PopupDialogStateProps,
    {},
    State> = state => {
    const {popups} = selectPopup(state);
    const reportText = selectTechReportText(state);
    const comment = selectComment(state)

    return {
        popups,
        reportText,
        comment
    };
};

const dispatchProps: MapDispatchToProps<PopupDialogDispatchProps, {}> = {
    onPopupDelete: popupDelete,
    updateTechReportText,
    setComment
};

export const PopupDialogConnected = connect(
    mapStateToProps,
    dispatchProps
)(PopupDialog);
