import {Alert} from "@mui/material";
import {styled} from "@mui/material/styles";

export const StyledAlert = styled(Alert)`
  background-color: white;
  border: 1px solid green;
  color: black;
  
  .MuiAlert-icon {
    color: green !important;
  }
`;
