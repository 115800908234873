import {State} from "../store/rootReducers";
import {getLocaleStorageItem, PAIR_STORAGE_NAME} from "../helper/other";

export function selectAuth(state: State) {
    return {...state.auth};
}

export function selectUploadFiles(state: State) {
    return state.uploads.files;
}

export function selectUploadsPageCount(state: State) {
    return state.uploads.pageCount;
}

export function selectCommentPopupOpen(state: State) {
    return state.uploads.commentPopupOpen;
}

export function selectDetailedFilePages(state: State) {
    return state.uploads.page;
}

export function selectUploadsIsLoading(state: State) {
    return state.uploads.isLoading;
}

export function selectUploadDetailedFile(state: State) {
    return state.uploads.detailedFile;
}

export function selectIdsOfSelectedFile(state: State) {
    return state.uploads.selectedIds;
}

export function selectShowPreviewPages(state: State) {
    return state.uploads.showPreviewPages;
}

export function selectUploadPopupIsOpen(state: State) {
    return state.uploads.isUploadPopupOpen;
}

export function selectUploadPackage(state: State) {
    return state.uploads.pair;
}

export function selectTechReportText(state: State) {
    return state.uploads.reportText;
}

export function selectComment(state: State) {
    return state.uploads.comment;
}

export function selectFirstDocumentLoading(state: State) {
    return state.uploads.firstDocumentLoading;
}

export function selectSecondDocumentLoading(state: State) {
    return state.uploads.secondDocumentLoading;
}

export function selectFirstDocumentFiles(state: State) {
    return state.uploads.firstDocumentFiles;
}

export function selectSecondDocumentFiles(state: State) {
    return state.uploads.secondDocumentFiles;
}
export function selectIsSnackbarOpen(state: State) {
    return state.uploads.isSnackbarOpen;
}

export function selectPackageFromStorage() {
    let item;
    const storageItem = getLocaleStorageItem(PAIR_STORAGE_NAME);
    try {
        item = JSON.parse(storageItem);
    } catch {
        item = undefined;
    }
    return item ? item.package : undefined;
}
