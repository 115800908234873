import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {getBalance, IParsedToken, logout} from "../../ducks/auth";
import {NavigationBar, NavigationBarDispatchProps, NavigationBarStateProps,} from "./navigationBar";
import {State} from "../../store/rootReducers";
import {setUploadPopupOpen} from "../../ducks/upload";
import {selectHeader, selectPath} from "../../selectors/other";
import {getToken, parseJwt} from "../../helper/other";
import {selectBalance} from "../../selectors/auth";
import {
    getExtendParams,
    getPdf,
    setDifferenceBarPosition,
    setExtendParams,
    setFirstDocumentPosition,
    setIsMagnifierActive,
    setLeftScale,
    setMenuOpen,
    setRightScale,
    setScrollSync,
    setZoomCount,
    updateDocFilterList
} from "../../ducks/montitoring";
import {
    selectDifferenceBarPosition,
    selectDocFilterList,
    selectFirstDocumentPosition,
    selectMagnifierAdded,
    selectMenuOpen,
    selectScrollSync,
    selectZoomCount
} from "../../selectors/monitoring";
import {ROUTES} from "../../app/Routes";

const mapStateToProps: MapStateToProps<NavigationBarStateProps, {}, State> = state => {
    let login = "";
    const path = selectPath(state, false);
    const pathProcessed = selectPath(state);
    const panelPosition = selectDifferenceBarPosition(state);
    const docPosition = selectFirstDocumentPosition(state);
    const isDetailedPage = pathProcessed === ROUTES.Doc;
    const token = getToken('user-info');
    const balance =  selectBalance(state);
    let activeAccountId;
    let isUserInfo = false;
    if(token) {
        const parsedToken = parseJwt(token) as IParsedToken;
        if(parsedToken){
            login = parsedToken.FirstName;
            activeAccountId = parsedToken.ActiveAccountComparerId
            isUserInfo = true;
            try {
                window.localStorage.setItem('PyrusWebWidgetUserName', `ID организации: ${parsedToken.OrganizationId},${parsedToken.FirstName},${parsedToken.Mail}`)
            }
            catch {

            }
        }
    }
    const scrollSync = selectScrollSync(state);

    return {
        docPosition,
        panelPosition,
        scrollSync,
        isDetailedPage,
        docFilterList: selectDocFilterList(state),
        isLoggedIn: !(path === "/auth"),
        login: login,
        header: selectHeader(state),
        balance: !isNaN(parseInt(balance)) ? parseInt(balance) : 0,
        menuOpen: selectMenuOpen(state),
        zoomCount: selectZoomCount(state),
        isMagnifierActive: selectMagnifierAdded(state),
        canGetBalance: !(path === ROUTES.Home) && !(path === ROUTES.Monitoring) ,
        activeAccountId,
        isUserInfo,
    };
};


const dispatchProps: MapDispatchToProps<NavigationBarDispatchProps, {}> = {
    onLogout: logout,
    setUploadPopupOpen,
    setScrollSync,
    getBalance,
    setMenuOpen,
    setDifferenceBarPosition,
    setFirstDocumentPosition,
    getPdf,
    setZoomCount,
    setIsMagnifierActive,
    updateDocFilterList,
    getExtendParams,
    setExtendParams,
    setLeftScale,
    setRightScale
};

export const NavigationBarConnected = connect(
    mapStateToProps,
    dispatchProps,
)(NavigationBar);
