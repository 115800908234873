import {getEndpoint} from "./endpoints";

export interface ICreatePackageProps {
    title: string;
    user: string;
    dbId: string;
}

export interface IPairUploadFile {
    name: string,
    mimeType: string,
    base64Content: string
}

export enum PairDocs {
    First = 'First',
    Second = 'Second',
}

export interface IAddFilesToPackage {
    pairId: number;
    doc: PairDocs;
    files: IPairUploadFile[];
}

export interface IPair {
    key: string;
    id: number;
}

export interface IAddedFile {
    imageIds: number[];
}

export interface IImagesByPackageId {
    imgId: number[];
}

export interface IConvertedImage {
    rawBase64: string;
}

class UploadApi {
    async createPair() {
        return await getEndpoint("/pairs",)
            .post()
            .res();
    }

    async addFileToPackage(props: IAddFilesToPackage) {
        return await getEndpoint(`/pairs/${props.pairId}/${props.doc}`,)
            .post({files: props.files})
            .res();
    }

    async getImageById(imageId: number) {
        return await getEndpoint(`/images/${imageId}`,)
            .post()
            .res();
    }

    async getImagesByPackageId(packageId: number) {
        return await getEndpoint(`/images/package/${packageId}`,)
            .post()
            .res();
    }

    async sendPairToRecognition(packageId: number) {
        return await getEndpoint(`/pairs/${packageId}/start`,)
            .post({id: packageId})
            .res();
    }

    async sortPackage(packageId: number) {
        return await getEndpoint(`/pairs/${packageId}/start`,)
            .post()
            .res();
    }
}

export function getUploadApiObj() {
    return new UploadApi();
}