import React, {FC} from "react";
import {Alert, Snackbar, SnackbarCloseReason} from "@mui/material";
import {useSelector} from "react-redux";
import {selectIsSnackbarOpen} from "../../selectors/upload";
import {getStoreUtils} from "../../store/utils";
import {setIsSnackbarOpen} from "../../ducks/upload";
import {StyledAlert} from "./snackbar-styles";

export const UploadSuccessSnackbar: FC = () => {
    const isOpen = useSelector(selectIsSnackbarOpen);
    const dispatch = getStoreUtils().dispatch;
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setIsSnackbarOpen(false));
    };

    return <Snackbar sx={{
        '&.MuiSnackbar-root': {left: '10px'},
    }} open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <StyledAlert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{width: '100%'}}
        >
            Файлы загружены успешно. Распознаем и сравниваем...
        </StyledAlert>
    </Snackbar>
}
