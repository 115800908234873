import * as React from "react";
import styles from "./Spinner.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

export interface SpinnerStateProps {
  spinnerStatus: boolean;
}

export interface SpinnerDispatchProps {
}

export type SpinnerProps = SpinnerStateProps & SpinnerDispatchProps;

export class Spinner extends React.Component<SpinnerProps> {
  public render() {
    const { spinnerStatus } = this.props;

    return spinnerStatus ? (
      <div className={styles.spinnerBack}>
        <CircularProgress/>
      </div>
    ) : (
      <></>
    );
  }
}
