import React, {FC, useState} from "react";
import styles from './UploadPopup.module.scss';
import MyDropzone from "../dropzone/dropzone";
import {IDetailedDocumentPage, IDetailedFile, IFileFromInput, setIsSnackbarOpen,} from "../../ducks/upload";
import {PopupElement} from "../../ducks/popup";
import {asyncEncodeImageFileAsURL} from "../../helper/other";
import {Button, IconButton} from "@mui/material";
import {PairDocs} from "../../api/uploadApi";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import {getStoreUtils} from "../../store/utils";

export interface IUploadPopupStateProps {
    firstDocumentFiles: IFileFromInput[];
    secondDocumentFiles: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    isLoading: boolean;
    showPreviewPages: boolean;
    selectedIds: string[];
    page: IDetailedDocumentPage | undefined;
    isOpen: boolean;
    firstDocumentLoading: boolean;
    secondDocumentLoading: boolean;
}

export interface IUploadPopupDispatchProps {
    setUploadPageCount: (count: number) => void;
    setSelectedIds: (ids: string[]) => void;
    setDetailedFilePages: (page: IDetailedDocumentPage | undefined) => void;
    setUploadIsLoading: (isLoading: boolean) => void;
    setDetailedUpload: (uploadFile: IDetailedFile | undefined) => void;
    setUploads: (uploads: IFileFromInput[], usePrevUploads?: boolean, pairDoc?: PairDocs) => void;
    deleteUploads: (uploads: IFileFromInput[]) => void;
    uploadFiles: (uploads: IFileFromInput[], pairDoc: PairDocs) => void;
    popupPush: (popup: PopupElement) => void;
    setShowPreviewPages: (value: boolean) => void;
    setUploadPopupOpen: (value: boolean) => void;
    sendPackageToRecognition: () => void;
    onClearUploads: () => void;
    getUploads: () => void;
}

export type UploadPopupProps = IUploadPopupStateProps & IUploadPopupDispatchProps;

export const UploadPopup:FC = (props: UploadPopupProps) => {
    const [, setIsDropHidden] = useState(true);

    const handleFileDropFirst = async (acceptedFiles) => {
        setIsDropHidden(true);
        let newFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            await asyncEncodeImageFileAsURL(file)
                .then(async (base64res: any) => {
                    newFiles.push({
                        inputIndex: i,
                        id: `${new Date().getTime()}${i}`,
                        fileUrl: base64res,
                        fileName: file.name,
                        fileSize: file.size,
                        mimeType: file.type,
                    })
                });
        }
        const sendFiles = [...newFiles].filter(elem => {
            const extension = elem.fileName.split('.').pop();
            if(extension) {
                return [ 'pdf', 'PDF', 'docx','png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
            } else {
                return  false;
            }
        })
        if(sendFiles.length > 0){
            props.uploadFiles(sendFiles, PairDocs.First)
        }
    }
    const handleFileDropSecond = async (acceptedFiles) => {
        setIsDropHidden(true);
        let newFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            await asyncEncodeImageFileAsURL(file)
                .then(async (base64res: any) => {
                    newFiles.push({
                        inputIndex: i,
                        id: `${new Date().getTime()}${i}`,
                        fileUrl: base64res,
                        fileName: file.name,
                        fileSize: file.size,
                        mimeType: file.type,
                    })
                });
        }
        const sendFiles = [...newFiles].filter(elem => {
            const extension = elem.fileName.split('.').pop();
            if(extension) {
                return [ 'pdf', 'PDF', 'docx','png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
            } else {
                return  false;
            }
        });
        if(sendFiles.length > 0){
            props.uploadFiles(sendFiles, PairDocs.Second);
        }
    }

    return props.isOpen ? <div
        className={styles.popupBackground}>
        <div
            className={`${styles.popupContainer} ${styles.popupContainerEmptyDocList}`}>
            <IconButton
                onClick={() => {
                    props.setUploadPopupOpen(false)
                }}
                className={styles.closeIcon}
            >
            </IconButton>
            <div className={`${styles.uploadContainer}`}>
                {(props.firstDocumentFiles.length === 0 && !props.firstDocumentLoading) && <MyDropzone
                    isEmpty={[...props.firstDocumentFiles].length === 0}
                    files={[...props.firstDocumentFiles]}
                    setFiles={(files: IFileFromInput[]) => {
                        props.setUploads(files, false, PairDocs.First)
                    }}
                    onDropHandler={handleFileDropFirst}
                    pairDoc={PairDocs.First}
                />}
                {props.firstDocumentLoading && <Loading/>}
                {(props.firstDocumentFiles.length > 0 && !props.firstDocumentLoading)&& <LoadSuccess/>}
                {(props.secondDocumentFiles.length > 0 && !props.secondDocumentLoading)&& <LoadSuccess/>}
                {props.secondDocumentLoading && <Loading/>}
                {(props.secondDocumentFiles.length === 0 && !props.secondDocumentLoading) && <MyDropzone
                    pairDoc={PairDocs.Second}
                    isEmpty={[...props.secondDocumentFiles].length === 0}
                    files={[...props.secondDocumentFiles]}
                    setFiles={(files: IFileFromInput[]) => {
                        props.setUploads(files, false, PairDocs.Second)
                    }}
                    onDropHandler={handleFileDropSecond}
                />}
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                    onClick={props.onClearUploads}
                    className={`${styles.cancelButton}`}
                >
                   Очистить
                </Button>
                <Button
                    onClick={props.sendPackageToRecognition}
                    disabled={props.firstDocumentFiles.length === 0 || props.secondDocumentFiles.length === 0}
                    className={`${styles.uploadButton} ${(props.firstDocumentFiles.length === 0 || props.secondDocumentFiles.length === 0) ? styles.disabled : ''}`}
                >
                    Cравнить
                </Button>
            </div>
        </div>
    </div> : <></>
}

export function Loading() {
    return <div className={styles.loadingDocument}>
        <CircularProgress/>
    </div>
}

export function LoadSuccess() {
    return <div className={styles.uploadSuccess}>
        <div className={styles.uploadSuccessLabel}>
            Файлы успешно загружены
        </div>
        <CheckCircleOutlineSharpIcon
            fontSize={"large"}
            color={'success'}
        />
    </div>
}
