import React, {useCallback} from "react";
import styles from "./dropzone.module.scss";
import {useDropzone} from "react-dropzone";
import {IFileFromInput} from "../../ducks/upload";
import {PairDocs} from "../../api/uploadApi";

export interface IDropzoneProps {
    pairDoc: PairDocs;
    isEmpty?: boolean;
    files: IFileFromInput[];
    setFiles: (files: IFileFromInput[]) => void;
    onDropHandler: (params: any) => void;
}

export default function MyDropzone(props: IDropzoneProps) {
    const onDrop = useCallback(props.onDropHandler, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop,
        noDrag: false,
        accept: {
            'application/msword': ['.docx', '.doc'],
            'application/docx': [],
            'application/pdf': ['.pdf', '.PDF'],
            'text/rtf': ['.rtf'],
            'image/*': []
        },
        minSize: 0
    })
    return (
        <div className={`${styles.dropZone} ${!props.isEmpty ? styles.notEmpty : ''}`} {...getRootProps()}>
            <input
                {...getInputProps()}
            />
            {props.pairDoc === PairDocs.First && <div className={styles.dropZoneTitle}>Загрузите эталонный документ</div>}
            {props.pairDoc === PairDocs.Second && <div className={styles.dropZoneTitle}>Загрузите измененный документ</div>}
            <div className={`${styles.uploadTextContainer} ${styles.marginZero}`}>
                {!props.isEmpty && <div className={styles.imageSmallIcon}/>}
                <div className={`${props.isEmpty ? styles.dropZoneLabel : styles.smallLabel}`}>Кликните сюда для загрузки файлов</div>
                <div className={`${props.isEmpty ? styles.dropZoneLabel : styles.smallLabel}`}>или перетащите файлы сюда</div>
                {props.isEmpty && <div className={styles.imageIcon}/>}
            </div>
            {props.isEmpty && <div className={styles.dropZoneFooter}>Поддерживаются следующие файлы: PDF, DOCX, DOC, JPG, TIFF, PNG, RTF</div>}
        </div>
    )
}
