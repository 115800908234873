
export class CustomAbortController {
    controller: AbortController | undefined;

    public setNewController = () => {
        this.controller = new AbortController();
    }

}

export const customControllerUtils = new CustomAbortController();