import React, {useLayoutEffect, useRef, useState} from "react";
import {PopupElement} from "../../ducks/popup";
import {
    DifferenceType,
    IDifferenceData,
    IDiscrepancyStatus,
    ITechReport,
    IUpdateDocument
} from "../../api/monitoringApi";
import styles from "../../pages/DifferencePage/DifferencePage.module.scss";
import Scrollbar from "react-scrollbars-custom";
import {getDifferenceData} from "../../selectors/other";
import {IconButton, Paper} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {Icon} from "@iconify/react";
import deleteTextSvg from "../../pages/DifferencePage/delete_text.svg";
import {DifferenceBarPosition} from "../navigationBar/navigationBar";
import {ScrollState} from "react-scrollbars-custom/dist/types/types";

export interface IDifferenceListSidebarStateProps {
    differenceData: IDifferenceData[];
    detailedDiff: string | undefined;
    menuOpen: boolean;
    isChangingDetAiledDiff: boolean;
    isChangingView: boolean;
    panelPosition: string;
    docPosition: string;
    urlId: any;
    docFilterList: string[];
    barWidth: number;
}

export interface IDifferenceListSidebarDispatchProps {
    setDetailedDiff: (diff: string | undefined) => void;
    popupPush: (popup: PopupElement) => void;
    routeTo: (route: string | null, id?: number) => void;
    updateDocumentStatus: (documentId: string, props: IUpdateDocument, helpForm?: boolean) => void;
    setDifferenceStatus: (index: number, status: IDiscrepancyStatus) => void;
    deleteUnrecognizedPackagesImages: (packageID: number) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
    setIsTrackDisabled: (flag: boolean) => void;
    setIsChangingDetailedDiff: (flag: boolean) => void;
    sendTechnicalReport: (props: ITechReport) => void;
    updateTechReportText: (value: string) => void;
    setDifferenceData: (data: IDifferenceData[]) => void;
    setMenuOpen: (flag: boolean) => void;
}

export type DifferenceListSidebarProps = IDifferenceListSidebarStateProps & IDifferenceListSidebarDispatchProps;

const theme = (color) => {
    // @ts-ignore
    return createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    popper: {
                        marginLeft: '10px',
                        left: '20px',
                    },
                    tooltip: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        backgroundColor: `${color} !important`,
                        borderColor: '#000',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        marginLeft: '10px',
                        left: '20px',
                        ":root": {
                            backgroundColor: `${color} !important`,
                        },
                    }
                }
            }
        }
    })
}

export default function DifferenceListSidebar (props: DifferenceListSidebarProps) {
    const isPanelLeft = props.panelPosition === DifferenceBarPosition.LEFT || !props.panelPosition;
    const {barWidth, detailedDiff} = props;
    const scrollBarRef: any = useRef();
    const [prevScrollState, setPrevScrollState] = useState<ScrollState | undefined>(undefined);
    const onSetDetailedDiff = (diff: string | undefined) => {
        if (diff) {
            props.setDetailedDiff(diff);
        }
    }

    const scrollToFitItem = (item) => {
        try{
            if (item.getBoundingClientRect().top < 0 || item.scrollHeight < item.getClientRects()[0].height){
                item.scrollIntoView({inline: 'center', block: 'nearest'})
            }
        }
        catch {

        }
    }

    useLayoutEffect(() => {
        setPrevScrollState(() => (scrollBarRef.current.getScrollState()))
    }, [props.menuOpen])

    useLayoutEffect(() => {
       try {
           if(!props.isChangingView){
               if(prevScrollState){
                   const scrollableHeightBefore = prevScrollState.scrollHeight - prevScrollState.clientHeight;
                   const scrollPositionPercentage = (prevScrollState.scrollTop / scrollableHeightBefore) * 100;
                   const newScrollState = scrollBarRef.current.getScrollState();
                   const scrollableHeightAfter = newScrollState.scrollHeight - newScrollState.clientHeight;
                   setTimeout(() => {
                       scrollBarRef.current.scrollTo(undefined, (scrollPositionPercentage / 100) * scrollableHeightAfter);
                       const discrepancyGroup = document.getElementsByClassName(`${styles.commentsContainerItem} ${styles.selectedItem}`)?.item(0)
                       scrollToFitItem(discrepancyGroup)
                   }, 50)
               }

           }
       }
       catch {

       }
    }, [props.isChangingView])

    return  <div
        className={`${styles.commentsContainer} ${styles.commentsEmptyContainer}`}
        style={{
            paddingRight: `${isPanelLeft ? 2.5 : 0}px`,
            paddingLeft: `${!isPanelLeft ? 2.5 : 0}px`,
        }}
    >
        <Scrollbar
            ref={scrollBarRef}
            width={"100%"}
            height={"100%"}
        >
            {(props.differenceData.length === 0 && props.menuOpen) && <div className={`${styles.emptyText}`}>
                Расхождений не обнаружено
            </div>}
            {props.differenceData.length > 0 && <div className={`${styles.subContainer} ${styles.withoutBackground}`}>
                {props.differenceData.map((diff, topIndex) => {
                    const diffID = `${diff.indexFromApi}`;
                    const diffData = getDifferenceData(diff.type);
                    const width = barWidth;
                    const hide = diff.discrepancyElements && diff.discrepancyElements.length > 0 && diff.discrepancyElements.filter(discrepancy => props.docFilterList.includes(discrepancy)).length === 0;
                    return !hide ? <Paper
                        key={`${new Date().getTime()}${topIndex}`}
                        elevation={0}
                        className={`${styles.commentsContainerItem}  ${(props.detailedDiff === diffID) && styles.selectedItem} ${width < 150 ? styles.centerIcon : ""}`}
                        onClick={(e) => {
                            onSetDetailedDiff(diffID);
                            if (detailedDiff === diffID) {
                                scrollToFitItem(e.currentTarget)
                            }
                        }}
                    >
                        <div
                            className={`${styles.commentsContainerItemWrapper} ${width < 150 ? styles.wrapperNoMargin : ""}`}>
                            <div
                                className={`${styles.commentsContainerItemWrapperItem} ${width < 150 ? styles.wrapperNoMargin : ""} ${width < 150 ? styles.centerIcon : ""} ${styles[diffData.color]}`}>
                                {width >= 150 && <div className={styles.nameRow}>
                                                <span>
                                                    {diffData.name}
                                                </span>
                                    <IconButton
                                        title={'Отметить как важное'}
                                        className={`${diff.important ? styles.pushpinIconActive : ""}`}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                        size={"small"}
                                        onClick={(e) => {
                                            if(diff.important){
                                                e.stopPropagation()
                                                e.nativeEvent.stopImmediatePropagation();

                                                props.setDifferenceStatus(topIndex, {
                                                    important: !diff.important
                                                })
                                            } else {
                                                e.stopPropagation()
                                                e.nativeEvent.stopImmediatePropagation();

                                                props.setDifferenceStatus(topIndex, {
                                                    important: !diff.important
                                                })
                                            }
                                        }}>
                                        {diff.important ?
                                            <StarIcon
                                                className={styles.pushpinIcon}
                                                fontSize="inherit"
                                            /> :
                                            <StarBorderIcon
                                                className={styles.pushpinIcon}
                                                fontSize="inherit"
                                            />}
                                    </IconButton>
                                </div>}
                                {width < 150 && <React.Fragment
                                >
                                    <ThemeProvider theme={theme(`#F2F7FA`)}>
                                        <Tooltip
                                            sx={{
                                                backgroundColor: `${diffData.colorValue} !important`,
                                            }}
                                            placement={'right'}
                                            title={<React.Fragment>
                                                <div
                                                    className={`${styles.commentsContainerItemWrapperItem} ${styles[diffData.color]}`}>
                                                    <span>{diffData.name}</span>
                                                </div>
                                                {(diff.type === DifferenceType.Added && diff.addedText) &&
                                                <div
                                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                                    <div className={styles.text}>{diff.addedText}</div>
                                                </div>}
                                                {(diff.type === DifferenceType.Deleted && diff.deletedText) &&
                                                <div
                                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                                    <div className={styles.text}>{diff.deletedText}</div>
                                                </div>}
                                                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) &&
                                                <React.Fragment>
                                                    <div
                                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                                        <div className={styles.text}> <span
                                                            className={styles.bold}>До:</span> {diff.changedTextBefore}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                                        <div className={styles.text}>
                                                          <span className={styles.bold}>После:</span>  {" " + diff.changedTextAfter}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: `100%`,
                                                }}
                                            >
                                                {diff.type === DifferenceType.Added &&
                                                <Icon icon="tabler:text-plus" width="32" height="32"/>}
                                                {diff.type === DifferenceType.Deleted &&
                                                <img style={{objectFit: 'contain'}} width={32} height={32}
                                                     src={deleteTextSvg} alt={''}/>}
                                                {diff.type === DifferenceType.Changed &&
                                                <Icon icon="mdi:file-document-edit-outline"
                                                      color={`${diffData.colorValue}`} width="32"
                                                      height="32"/>}
                                                {diff.type === DifferenceType.DeletedPages &&
                                                <Icon icon="mdi:file-document-remove-outline"
                                                      color={`${diffData.colorValue}`} width="32"
                                                      height="32"/>}
                                                {diff.type === DifferenceType.AddedPages &&
                                                <Icon icon="mdi:file-document-plus-outline"
                                                      color={`${diffData.colorValue}`} width="32"
                                                      height="32"/>}
                                                {diff.type === DifferenceType.ChangedPages &&
                                                <Icon icon="iconamoon:compare"
                                                      color={`${diffData.colorValue}`} width="32"
                                                      height="32"/>}
                                            </div>
                                        </Tooltip>
                                    </ThemeProvider>

                                </React.Fragment>}
                            </div>
                            {width >= 150 && <React.Fragment>
                                {(diff.type === DifferenceType.Added && diff.addedText) && <div
                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                    <div className={styles.text}>{diff.addedText}</div>
                                </div>}
                                {(diff.type === DifferenceType.Deleted && diff.deletedText) && <div
                                    className={`${styles.commentsContainerItemWrapperItem}`}>
                                    <div className={styles.text}>{diff.deletedText}</div>
                                </div>}
                                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) &&
                                <React.Fragment>
                                    <div
                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                        <div className={styles.text}> <span
                                            className={styles.bold}>До:</span> {diff.changedTextBefore}
                                        </div>
                                    </div>
                                    <div
                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                        <div className={styles.text}>
                                                                             <span
                                                                                 className={styles.bold}>
                                                                                 После:
                                                                             </span>
                                            {" " + diff.changedTextAfter}
                                        </div>
                                    </div>
                                </React.Fragment>}
                            </React.Fragment>}
                        </div>
                    </Paper> : <div key={`${new Date().getTime()}${topIndex}`}/>
                })}
            </div>}
        </Scrollbar>
    </div>
}
