import React, {useLayoutEffect, useRef, useState} from 'react';
import styles from "./DifferenceDocument.module.scss";
import {Paper} from "@mui/material";
import {DifferenceType, IDifferencePart} from "../../api/monitoringApi";
import {getObjectFitSize, getSideRatio, ifHorizontal} from "../../helper/other";
import CircularProgress from "@mui/material/CircularProgress";
import {getDifferenceData} from "../../selectors/other";
import {useMouse, useMouseOverZoom} from "../../hooks/imageZoomHooks";
import {useInView} from 'react-intersection-observer';

export enum PageParts {
    Right = "Right",
    Left = "Left",
    Comment = "Comment",
}

export interface DifferenceDocumentProps {
    imageSrc: string;
    fullHeight: boolean;
    displayMagnifier: boolean;
    isMagnifierActive: boolean;
    isChangingView: boolean;
    loading: boolean;
    menuOpen: boolean;
    items: IDifferencePart[];
    pageIndex: number;
    docFilterList: string[];
    scale: number;
    side: PageParts,
    detailedDiff: string | undefined;
    setDetailedDiff: (diff: string | undefined) => void;
    setScale: (scale: number) => void;
    setIsTrackDisabled: (flag: boolean) => void;
    setIsChangingDetailedDiff: (flag: boolean) => void;
    setDisplayMagnifier: (flag: boolean) => void;
    zoom: number;
    rotate: number;
    containerRef: React.RefObject<HTMLDivElement>;
    pagePartRef: React.RefObject<HTMLDivElement>;
    onLoad?: () => void;
}

export interface IDifferenceDocumentState {
    isScrolling: boolean;
    clientX: number;
    clientY: number;
    scrollX: number;
    scrollY: number;
}

export interface ICanvasDifferenceElement {
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
    colorFill: string;
    isDetailed: boolean;
}

const MAGNIFIER_SIZE = 350;
const MAGNIFIER_OFFSET = 50;

export function DifferenceDocument(props: DifferenceDocumentProps) {
    const [isLoaded, setIsLoaded] = useState(undefined);
    const {rotate} = props

    const imageRef: React.RefObject<HTMLImageElement> = useRef();
    const {detailedDiff} = props;
    useLayoutEffect(() => {
        try {
            if (props.detailedDiff && props.items.find(elem => `${elem.indexFromApi}` === `${props.detailedDiff}`) && !props.isChangingView) {
                props.setIsChangingDetailedDiff(true);
                const discrepancy = document.getElementById(`${detailedDiff}/diff/${props.side}`)
                if (discrepancy) {
                    discrepancy.scrollIntoView({inline: 'center', block: 'center'})
                }
                setTimeout(() => {
                    props.setIsChangingDetailedDiff(false);
                }, 25)
            }
        } catch (e) {
            console.log("error", e)
        }

    }, [detailedDiff, props.isChangingView]);

    let imageObject = {
        width: 0,
        height: 0,
        x: 0,
        y: 0
    }
    let rateWidth = 0;
    let rateHeight = 0;
    let offsetY = 0;
    let offsetX = 0;

    if (imageRef && imageRef.current) {
        const htmlImgElem = imageRef.current;
        const imageSizes = getObjectFitSize(true, htmlImgElem.clientWidth, htmlImgElem.clientHeight, htmlImgElem.naturalWidth, htmlImgElem.naturalHeight,);
        imageObject = {...imageSizes}
        rateWidth = imageObject.width / htmlImgElem.naturalWidth;
        rateHeight = imageObject.height / htmlImgElem.naturalHeight;
        offsetY = imageObject.y;
        offsetX = imageObject.x;
    }

    const target = useRef<HTMLCanvasElement>(null);
    const targetOrigin = useRef<HTMLCanvasElement>(null);
    const cursor = useRef<HTMLDivElement>(null);
    const paper = useRef<HTMLDivElement>(null);
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });
    const {x, y, isActive} = useMouse(paper, props.zoom, rotate);
    const mouse3
        = useMouse(props.containerRef, props.zoom, rotate);
    const isVisible = (inView || (props.detailedDiff && props.items.find(elem => `${elem.indexFromApi}` === `${props.detailedDiff}`)));
    useMouseOverZoom(
        imageRef,
        target,
        targetOrigin,
        cursor,
        paper,
        props.zoom,
        props.items.map((el, index) => {
            const diffData = getDifferenceData(el.type)
            const display = props.side === PageParts.Left ? ![DifferenceType.Added, DifferenceType.AddedPages].includes(el.type) : ![DifferenceType.Deleted, DifferenceType.DeletedPages].includes(el.type)
            const hide = el.discrepancyElements && el.discrepancyElements.length > 0 && el.discrepancyElements.filter(discrepancy => props.docFilterList.includes(discrepancy)).length === 0;

            return (display && !hide) ? {
                color: diffData.colorValue,
                x: (((el.coordinates.left) * rateWidth) + (props.scale * offsetX)) - (2 * (rateWidth)),
                y: (((el.coordinates.top) * rateHeight) + (props.scale * offsetY)) - (4 * (rateHeight)) ,
                height: (rateHeight * (el.coordinates.height)) + 4 * props.scale,
                width: ((el.coordinates.width) * rateWidth) + 4 * props.scale,
                colorFill: diffData.colorFill,
                isDetailed: props.detailedDiff === `${el.indexFromApi}`,
            } : undefined
        }).filter(elem => elem),
        props.fullHeight,
        25,
        rotate
    )

    const getCanvasWidthOrHeight = (paper: React.RefObject<HTMLElement>, point: number, size: number, directions: 'width' | 'height' = 'width') => {
        if (paper && paper.current) {
            if (directions === 'width') {
                const difference = point - size + MAGNIFIER_OFFSET;
                const sum = point + size + MAGNIFIER_OFFSET;
                if(difference < 0){
                    return  5 * (-1);
                } else if(sum >= paper.current.clientWidth) {
                    return size + 2 * MAGNIFIER_OFFSET;
                }
                return 5 * (-1);

            } else {
                const sum = mouse3.y + size + MAGNIFIER_OFFSET;
                const sumContainer = point + size + MAGNIFIER_OFFSET;
                const maxHeight = props.containerRef.current.clientHeight;
                const maxHeightContainer = paper.current.clientHeight;
                const minHeight = props.containerRef.current.scrollTop;
                if(sum >= maxHeight || sumContainer >= maxHeightContainer) {
                    return  Math.max(sum - maxHeight + 5, sumContainer - maxHeightContainer + 5);
                } else if(x <= minHeight){
                    return  5 * (-1);
                }
                return 5 * (-1);
            }
        } else {
            return size;
        }
    }

    const getRotateInverted = (value: number): { inverted: string | undefined, rotate: string | undefined } => {
        let rotate;
        let inverted;

        switch (value) {
            case 90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate90;
                break;
            case 180:
                inverted = undefined;
                rotate = styles.rotate180;
                break;
            case 270:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate270;
                break;
            case -90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotateMinus90;
                break;
            case -180:
                inverted = undefined;
                rotate = styles.rotateMinus180;
                break;
            case -270:
                rotate = styles.rotateMinus270;
                inverted = styles.rotateHorizontal
                break;
            default:
                inverted = undefined;
                rotate = undefined;
                break;
        }

        return {
            inverted,
            rotate,
        }
    }

    return <Paper
        onMouseMove={() => {
            props.setDisplayMagnifier(true)
        }}
        ref={paper}
        style={{
            width: `${props.loading ? 100 : 100 * (props.scale)}%`,
            marginLeft: (rotate === -90 || rotate === 90 || rotate === 270 || rotate === -270) ? props.scale > 1.7 ? `${getSideRatio(paper.current)*50}%` : props.scale > 1.3 ? `${getSideRatio(paper.current)*40}%` : ifHorizontal(paper.current) ? 0 : `${getSideRatio(paper.current)*30}%` : 0,
            marginTop: (rotate === -90 || rotate === 90 || rotate === 270 || rotate === -270) ? ifHorizontal(paper.current) ? props.scale > 1.7 ? '20%' : '15%' : 0 : 0,
            marginBottom: ((rotate === -90 || rotate === 90 || rotate === 270 || rotate === -270) && ifHorizontal(paper.current)) ? props.scale > 1.7 ? `${getSideRatio(paper.current)*20}%` : props.scale > 1.5 ? `${getSideRatio(paper.current)*15}%` : `${getSideRatio(paper.current)*10}%` : 0,
            paddingTop: ((rotate === 90 || rotate === -270) && ifHorizontal(paper.current)) ? '20%' : 0,
            paddingBottom: ((rotate === -90 || rotate === 270) && ifHorizontal(paper.current)) ? '20%' : 0,
        }}
        key={`${props.side}${props.pageIndex}`}
        elevation={0}
        id={`pdfDoc${props.pageIndex}`}
        className={`${styles.pdfFile} ${getRotateInverted(rotate).rotate}`}
    >
        <div
            style={{
            }}
            ref={ref}
            id={`pageContainer${props.side}${props.pageIndex}`}
            className={`${styles.pageContainer} `}
        >
            {!props.loading && <img

                ref={imageRef}
                onLoad={(event) => {
                    setIsLoaded(true)
                }}
                id={`docImage${props.side}${props.pageIndex}`}
                alt={"Uploading error"}
                style={{
                    zIndex: 100,
                    width: `calc(100% - 10px)`,
                    height: `100%`,
                    objectFit: `contain`,
                    visibility: (inView || (props.detailedDiff && props.items.find(elem => `${elem.indexFromApi}` === `${props.detailedDiff}`)))
                        ? 'visible'
                        : 'hidden'
                }}
                src={props.imageSrc}
            />}
            {
                props.loading && <div
                    style={{
                        width: `100%`,
                        height: `${(props.pagePartRef && props.pagePartRef.current) ? `${(props.pagePartRef.current.clientHeight)/(props.fullHeight ? 1 : 2)}px` : '400px'}`
                    }}
                    className={styles.loadingContainer}
                >
                    <CircularProgress/>
                </div>
            }
            {(!props.loading && !props.isChangingView) && props.items.map((el, indexEl) => {
                const diffData = getDifferenceData(el.type)
                const display = props.side === PageParts.Left ? ![DifferenceType.Added, DifferenceType.AddedPages].includes(el.type) : ![DifferenceType.Deleted, DifferenceType.DeletedPages].includes(el.type)
                const hide = el.discrepancyElements && el.discrepancyElements.length > 0 && el.discrepancyElements.filter(discrepancy => props.docFilterList.includes(discrepancy)).length === 0;

                return <div
                    id={`${el.indexFromApi}/diff/${props.side}`}
                    key={`${el.imageId}${props.side}${el.pageIndex}${indexEl}`}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        props.setDetailedDiff(`${el.indexFromApi}`);
                    }}
                    style={{
                        visibility: `${(!display || !isVisible || props.isChangingView || hide) ? 'hidden' : 'visible'}`,
                        top: `${(((el.coordinates.top) * rateHeight) + (props.scale * offsetY)) - (4 * (rateHeight))
                        }px`,
                        left: `${(((el.coordinates.left) * rateWidth) + (props.scale * offsetX)) - (2 * (rateWidth))

                        }px`,
                        width: `${((el.coordinates.width) * rateWidth) - 4 * props.scale

                        }px`,
                        height: `${(rateHeight * (el.coordinates.height)) - 4 * props.scale
                        }px`,
                    }}
                    className={`${styles.polyLine} ${(props.detailedDiff === `${el.indexFromApi}`) && styles[`${diffData.color}Selected`]} ${styles[diffData.color]}`}
                >
                </div>
            })}
            {isActive && props.displayMagnifier && isVisible && props.isMagnifierActive && paper.current && x >= 0 && y >= 0 && <canvas
                style={{
                    objectFit: 'contain',
                    width:  props.zoom >= 100 ? MAGNIFIER_SIZE : MAGNIFIER_SIZE * props.zoom * 0.01,
                    height: props.zoom >= 100 ? MAGNIFIER_SIZE : MAGNIFIER_SIZE * props.zoom * 0.01,
                    visibility: 'visible',
                    transform: `rotate(${-rotate}deg)`,
                    left: (
                        rotate === 0 ? x:

                            (rotate === -90 || rotate === 270) ?
                                imageRef.current.width - y:

                                (rotate === -180 || rotate === 180) ?
                                    imageRef.current.width - x:

                                    /*(rotate == -270 || .rotate == 90)*/
                                    y

                    ) + MAGNIFIER_OFFSET - (props.zoom >= 100 ? getCanvasWidthOrHeight(paper, x, MAGNIFIER_SIZE, 'width') : getCanvasWidthOrHeight(paper, x, MAGNIFIER_SIZE, 'width') * props.zoom * 0.01),

                    top: (
                        rotate === 0 ? y:

                            (rotate === -90 || rotate === 270) ? x:

                                (rotate === -180 || rotate === 180) ? imageRef.current.height - y:

                                    /*(rotate == -270 || rotate == 90)*/
                                    imageRef.current.height - x

                    ) + MAGNIFIER_OFFSET - (props.zoom >= 100 ?  getCanvasWidthOrHeight(paper, y, MAGNIFIER_SIZE, 'height') : getCanvasWidthOrHeight(paper, y, MAGNIFIER_SIZE, 'height') * props.zoom * 0.01),
                }}
                ref={target}
                className={styles.canvasArea}
            />}
            {!props.loading && isVisible && isLoaded && imageRef.current && imageRef.current.width && imageRef.current.height && paper.current &&  x >= 0 && y >= 0 && <canvas
                style={{
                    width: imageRef.current.width,
                    height: imageRef.current.height,
                    visibility: 'hidden',
                    left: 0,
                    top: 0,
                    zIndex: -100,
                }}
                ref={targetOrigin}
                className={styles.canvasArea}
            />}
        </div>
    </Paper>

}
