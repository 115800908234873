import styles from './MonitoringPage.module.scss';
import React, {useEffect, useState} from "react";
import {ITableProps, Table} from "../../components/table/Table";
import PageWrapper from "../../components/pageWrapper/PageWeapper";
import {ROUTES} from "../../app/Routes";
import moment from "moment";
import {getLocaleStorageItem, tableCustomDateSort, truncate} from "../../helper/other";
import "moment/locale/ru";
import {MONITORING_STATUSES, MonitoringStatuses, setSortType, SortDirections} from "../../ducks/montitoring";
import {Select} from "@mui/material";
import {styled} from "@mui/styles";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'rsuite/styles/index.less';
import "./calendarCSS.css";
import {IMonitoringFilter} from "../../api/monitoringApi";
import {getStoreUtils} from "../../store/utils";
import {Column} from "material-table";
import {getSortType, setStorageSortType} from "../../selectors/monitoring";

export interface IFilterOption {
    value: number;
    label: string;
    icon?: string;
    isHidden?: boolean;
}

export const FILTER_OPTIONS: IFilterOption[] = [
    {
        value: MonitoringStatuses.NEW,
        label: MONITORING_STATUSES[MonitoringStatuses.NEW],
    },
    {
        value: MonitoringStatuses.SENDING,
        label: MONITORING_STATUSES[MonitoringStatuses.SENDING],
    },
    {
        value: MonitoringStatuses.COMPARED,
        label: MONITORING_STATUSES[MonitoringStatuses.COMPARED],
    },
    {
        value: MonitoringStatuses.HAS_DIFFERENCES,
        label: MONITORING_STATUSES[MonitoringStatuses.HAS_DIFFERENCES],
    },
    {
        value: MonitoringStatuses.HAS_NO_DIFFERENCES,
        label: MONITORING_STATUSES[MonitoringStatuses.HAS_NO_DIFFERENCES],
    },
    {
        value: MonitoringStatuses.FINISHED,
        label: MONITORING_STATUSES[ MonitoringStatuses.FINISHED],
    },
    {
        value: MonitoringStatuses.DELETED,
        label: MONITORING_STATUSES[ MonitoringStatuses.DELETED],
    },
];

export interface IMonitoringPageStateProps {
    list: IRecognitionActTable[];
    isUploadPopupOpen: boolean;
    options: string[];
    sort: string;
    listPage: number;
}

export interface IMonitoringPageDispatchProps {
    getRecognitionActs: (filter?: IMonitoringFilter, isSearch?: boolean) => void;
    routeTo: (route: string | null, id?: number) => void;
    setSortType: (sort: SortDirections | undefined) => void;
    setSearchOptions: (options: string[]) => void;
    setListPage: (pageNumber: number) => void;
    clearMonitoringList: () => void;
}

export interface IMonitoringPageState {
    dateFrom: string;
    dateTo: string;
    statuses: IFilterOption[];
    search: string;
    status: number;
    timeout: any;
}

export type MonitoringPageProps = IMonitoringPageStateProps & IMonitoringPageDispatchProps;

let interval = undefined;

export interface IRecognitionActTable {
    id: number;
    key: string;
    firstDocument: string;
    secondDocument: string;
    compareDate: string;
    comment: string;
    upload_date: string;
    status: string;
    statusId: number;
}

export const defaultTableColumns: Column<Object>[] = [
    {
        title: 'СТАТУС',
        field: 'status',
        sorting: true,
        render: rowData => {
            const {status} = rowData as IRecognitionActTable;
            return <div
                onClick={() => {
                }}
                className={styles.iconRow}>
                {status}
            </div>;
        }
    },
    {
        title: 'ДАТА ЗАГРУЗКИ',
        field: 'upload_date',
        sorting: true,

    },
    {
        title: 'ЭТАЛОН', sorting: false, field: 'firstDocument', render: rowData => {
            const {firstDocument} = rowData as IRecognitionActTable;
            return <div
                title={firstDocument}
            >
                {truncate(firstDocument, 50)}
            </div>;
        }
    },
    {
        title: 'ИЗМЕНЕННЫЙ ДОКУМЕНТ', sorting: false, field: 'secondDocument',
        render: rowData => {
            const {secondDocument} = rowData as IRecognitionActTable;
            return <div
                title={secondDocument}
            >
                {truncate(secondDocument, 50)}
            </div>;
        }
    },
    {title: 'КОММЕНТАРИЙ', sorting: false, field: 'comment',
        render: rowData => {
            const {comment} = rowData as IRecognitionActTable;
            return <div
                title={comment}
            >
                {truncate(comment, 50)}
            </div>;
        }
    },
];

const CustomSelect = styled(Select)(() => ({
    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent"
        },
        "&:hover fieldset": {
            borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent"
        }
    }
}));

export function MonitoringPage(props: MonitoringPageProps) {
    let stateFromStorage: IMonitoringPageState = undefined;

    try {
        //@ts-ignore
        stateFromStorage = JSON.parse(getLocaleStorageItem("stateFromStorage"));
    } catch (e) {
        console.log("e", e, stateFromStorage)
    }
    const [sort, setSort] = useState('upload_date')
    const data = sort === 'upload_date' ? props.list.sort((a, b) => tableCustomDateSort(
        b.upload_date,
        a.upload_date,
        "DD.MM.YYYY HH:mm:ss",
        props.sort ?? SortDirections.DESC
    )) : props.list.sort((a, b) => props.sort === SortDirections.ASC ? +a.statusId - +b.statusId : +b.statusId - +a.statusId)
    const tableProps: ITableProps = {
        pageSize: 18,
        onChangeListPage: (pageNumber => {
            props.setListPage(pageNumber)
        }),
        initialPage: props.listPage,
        data: [...data],
        //@ts-ignore
        columns: defaultTableColumns.map(c => {
            if (c.field === 'status' || c.field === 'upload_date') {
            const c1 = {...c, customSort : (a, b) => {
                const item = getSortType();
                if (interval) {
                    clearTimeout(interval)
                }
                interval = setTimeout(() => {
                    if (item === SortDirections.ASC) {
                        setStorageSortType(SortDirections.DESC)
                        getStoreUtils().dispatch(setSortType(SortDirections.DESC));
                    } else if (item === SortDirections.DESC || !item) {
                        setStorageSortType(SortDirections.ASC)
                        getStoreUtils().dispatch(setSortType(SortDirections.ASC));
                    }
                }, 0)
                setSort(c1.field)
                return 1
            }}
            return c1} else return c
        })
      ,
        onRowClick: (event, rowData) => {
            const {key} = rowData;
            props.routeTo(`${ROUTES.Docs}/${key}`);
        }
    }

    useEffect(() => {
        return () => {
            props.clearMonitoringList();
        }
    }, [])

    useEffect(() => {
        if (!props.isUploadPopupOpen) {
            props.getRecognitionActs({
                extendData: false,
                filter: {
                    status: [],
                }
            });
        }
    }, []);

    return <PageWrapper>
        <div className={styles.table}>
            <Table {...tableProps}/>
        </div>
    </PageWrapper>
}
