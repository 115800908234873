import {configureStore} from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import {history, rootReducers} from "./rootReducers";
import {routerMiddleware} from "connected-react-router";
import logger from "redux-logger";
import {getLocaleStorageItem} from "../helper/other";

let middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

let authState;

try {
  authState = JSON.parse(getLocaleStorageItem("authState") || '{"id": 0, "login": "", "password": ""}');
} catch (e) {
  authState = JSON.parse('{"id": 0, "login": "", "password": ""}');
}

const preloadedState = {auth: authState};

export const store = configureStore({
  reducer: rootReducers,
  middleware: [...middleware],
  preloadedState: preloadedState,
});

