import {PageParts} from "../components/differenceDocument/DifferenceDocument";
import {getStoreUtils} from "../store/utils";
import {selectDetailedDiff} from "../selectors/monitoring";
import jwt_decode from "jwt-decode";
import moment from "moment";
import {DifferenceBarPosition, FirstDocumentPosition} from "../components/navigationBar/navigationBar";

export const TEST_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBcHBsaWNhdGlvbktleSI6Ijg3MDNmNjVmLWExMTAtNGJhMS05ZTQ5LTA5N2I3MmYwMGY3NyIsIklkIjoiNjg3OCIsIlBhc3N3b3JkIjoiM0VCRDM2NDExMjQ0ODc2OUUxRkJBMUJEMTlFQzk3OEQxQThFQjcwOTRCRjlBNDc2QUUyOEY0QzdENzlGMjMwNiIsIkZpcnN0TmFtZSI6ItCw0LrQutCw0YPQvdGCINCx0LXQtyDQvtC_0YDQtdC00LXQu9C10L3QuNC5IiwiTWlkZGxlTmFtZSI6IiIsIkxhc3ROYW1lIjoi0LDQutC60LDRg9C90YIg0LHQtdC3INC-0L_RgNC10LTQtdC70LXQvdC40LkiLCJNYWlsIjoiIiwiUm9sZSI6ItCi0LXRhdC90LjRh9C10YHQutC40Lkg0L_QvtC70YzQt9C-0LLQsNGC0LXQu9GMIiwiT3JnYW5pemF0aW9uSWQiOiIxNDIiLCJJc0Rpc2FibGVkQnlPcmdhbml6YXRpb25BZG1pbiI6IkZhbHNlIiwiSXNCbG9ja2VkQnlDb3JyZWN0QWRtaW4iOiJGYWxzZSIsIklzRm9yMUNMb2FkZXIiOiJGYWxzZSIsIklzRm9yMUNFeHRlbnNpb24iOiJUcnVlIiwiZXhwIjoxNjgyNDIxMzQ0LCJpc3MiOiJBdXRoU2VydmljZSJ9.5rivb8gePoAtMbNyE6BG0-oiykhDDantG-4-ohrpvj4'
export const PAIR_STORAGE_NAME = 'pair';
export const DIFFERENCE_PANEL_POSITION_STORAGE_NAME = 'panel_position';
export const FIRST_DOCUMENT_POSITION_STORAGE_NAME = 'document_position';

export const DOC_FILTERS = {
    Underline: "Различать пробел и нижнее подчеркивание",
    Spaces: "Определять лишние пробелы",
    Case: "Учитывать регистр",
    ListItemLabel: "Различать разные маркеры списков",
    Dash: "Различать дефис и тире",
    Quotes: "Различать разные виды кавычек/апострофов",
    PunctuationMarks: "Проверять знаки препинания",
    DiaresisE: "Различать е и ё",
    Footer: "Сравнивать колонтитулы",
    PageNumber: "Сравнивать номера страниц",
    Stamp: "Проверять наличие печатей",
    Signs: "Проверять наличие подписей",
}

export const DEFAULT_DIFFERENCE_DOC_FILTERS_LIST = [];
export const UNITED_FILTERS_FIRST_ELEMENT = "Footer";
export const UNITED_FILTERS_SECOND_ELEMENT = "PageNumber";
export const DOC_DIFFERENCE_FILTERS_DISABLED_LIST = [];
export const DOC_DIFFERENCE_FILTERS_LIST: string[] = [
    "Underline",
    "Spaces",
    "Case",
    "ListItemLabel",
    "Dash",
    "Quotes",
    "PunctuationMarks",
    "DiaresisE",
    "Footer",
    "PageNumber",
    'Stamp',
    'Signs',
]

export const setPanelPosition = (value: DifferenceBarPosition) => {
    window.localStorage.setItem(DIFFERENCE_PANEL_POSITION_STORAGE_NAME, value);
}

export const setDocumentPosition = (value: FirstDocumentPosition) => {
    window.localStorage.setItem(FIRST_DOCUMENT_POSITION_STORAGE_NAME, value);
}

export const getPanelPosition = () => {
    return window.localStorage.getItem(DIFFERENCE_PANEL_POSITION_STORAGE_NAME);
}

export const getDocumentPosition = () => {
    return window.localStorage.getItem(FIRST_DOCUMENT_POSITION_STORAGE_NAME);
}

export interface FullName {
    firstName: string;
    middleName: string;
    lastName: string;
}

export function getLocaleStorageItem(cname: string) {
    return window.localStorage.getItem(cname);
}

export function setLocaleStorageItem(name: string, authState: object, exdays: number) {
    if (exdays <= 0) {
        window.localStorage.clear();
    } else {
        window.localStorage.setItem(`${name}`, JSON.stringify(authState));
    }
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const handleDoubleLinkClick = (href: string) => {
    const {getState} = getStoreUtils();
    const detailedDiff = selectDetailedDiff(getState());
    if (detailedDiff !== href) {
        let link1 = document.createElement('a');
        let link2 = document.createElement('a');
        document.body.appendChild(link1);
        document.body.appendChild(link2);
        link1.href = `#${href}${PageParts.Left}`;
        link2.href = `#${href}${PageParts.Right}`;
        setTimeout(() => {
            link2.click();
            document.body.removeChild(link2);
        }, 10)
        link1.click();

        document.body.removeChild(link1); //remove the link when done
        //remove the link when done
    }
}


export function truncate(str: string, maxlength: number, shorter: string = '...'): string {
    return str
        ? (str.length > maxlength) && maxlength !== 0
            ? str.slice(0, maxlength - 1).trim() + shorter
            : str
        : "";
}

export const findPageRec = (page: number, arr: number[]) => {
    if (page <= 0 || arr.length === 0) return undefined;
    const findPage = arr.find(elem => elem >= page);
    if (findPage) {
        return findPage;
    } else {
        return findPageRec(page - 1, arr);
    }
}
export const findNextIndex = (page: number, arr: any[]) => {
    if (page < 0 || arr.length === 0) return undefined;
    const index = arr[page];
    if (index) {
        return page;
    } else {
        return findNextIndex(page - 1, arr);
    }
}

export function getCookie(cname) {
    try {
        let name = encodeURIComponent(cname) + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return decodeURIComponent(c.substring(name.length, c.length));
            }
        }
    } catch (e) {
        console.log("getCookie error", e)
    }

    return "";
}

export function setCookie(name, value, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = encodeURIComponent(name) + "=" + value + ";" + expires + ";path=/";
}

export function parseJwt(token: string) {
    try {
        return jwt_decode(token, {header: true});
    } catch (e) {
        console.log('parseJwt error', e)
    }
}

export function getInitials(fullName: FullName, format: "lastname_and_initials" | "short" | "full") {
    let initials = '';
    const lastName = fullName.lastName ? fullName.lastName : "";
    const firstName = fullName.firstName ? fullName.firstName : "";
    const middleName = fullName.middleName ? fullName.middleName : "";

    switch (format) {
        case "short":
            initials = `${lastName[0]}${firstName[0]}`.toUpperCase();
            break;
        case "lastname_and_initials":
            initials = `${lastName} ${firstName ? firstName[0].toUpperCase() + '.' : ''} ${middleName ? middleName[0].toUpperCase() + '.' : ''}`;
            break;
        case "full":
            initials = `${lastName} ${firstName}${middleName ? ' ' + middleName : ''}`;
            break;
    }

    return initials
}

export async function asyncEncodeImageFileAsURL(file: any) {
    return new Promise(resolve => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            }
        };

        reader.readAsDataURL(file);
    });
}

export function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
    let doRatio = width / height;
    let cRatio = containerWidth / containerHeight;
    let targetWidth = 0;
    let targetHeight = 0;
    let test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}

export const getToken = (name: string = 'jwt-token') => {
    return getCookie(name);
}
export const divideNumbersByPosition = (number: number | string) => {
    return number ? number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
}

export const tableCustomDateSort = (d1: number | string, d2: number | string, format: string, sortType: string): number => {
    const dateA = sortType === 'desc' ? d1 : d2
    const dateB = sortType === 'desc' ? d2 : d1
    if (dateA === null || dateB === null)
        return dateA === null && dateB !== null
            ? -1
            : dateA !== null && dateB === null
                ? 1
                : 0;

    const date1 = moment(dateB, format).valueOf();
    const date2 = moment(dateA, format).valueOf();

    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
}

export const ifHorizontal = (elem) => {
    try {
        return elem.clientWidth > elem.clientHeight
    } catch (e) {
        return false
    }
}

export const getSideRatio = (elem) => {
    try {
        const {clientHeight: height, clientWidth: width} = elem
        return width > height ? width / height : height / width
    } catch (e) {
        return 0
    }
}
