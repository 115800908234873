import {connectRouter, RouterState} from "connected-react-router";
import {combineReducers} from "redux";
import {createBrowserHistory} from "history";
import {IUploadState, uploadsReducer} from "../ducks/upload";
import {authReducer, IAuthState} from "../ducks/auth";
import {IMonitoringState, monitoringReducer} from "../ducks/montitoring";
import {IPopupState, popupReducer} from "../ducks/popup";

export const UPDATE_CLEAR = "UPDATE_CLEAR";

export interface State {
    auth: IAuthState;
    uploads: IUploadState;
    monitoring: IMonitoringState;
    popup: IPopupState,
    router?: RouterState;
}

export const history = createBrowserHistory({
    basename: `/testcomparer`
});

export const rootReducers = combineReducers<State>({
    auth: authReducer,
    uploads: uploadsReducer,
    monitoring: monitoringReducer,
    popup: popupReducer,
    router: connectRouter(history)
});
