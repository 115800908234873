import React, {FC, useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from './navigationBar.module.scss';
import {
    Button,
    Checkbox,
    ClickAwayListener,
    createTheme,
    FormControlLabel,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Slider,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import docTopIconActive from './docTopIconActive.svg';
import saveToPdf from './saveToPdf.svg';
import docLeftIconActive from './docLeftIconActive.svg';
import {Icon} from '@iconify/react';
import {
    DEFAULT_DIFFERENCE_DOC_FILTERS_LIST,
    DOC_DIFFERENCE_FILTERS_DISABLED_LIST,
    DOC_DIFFERENCE_FILTERS_LIST,
    DOC_FILTERS
} from "../../helper/other";
import {ROUTES} from "../../app/Routes";
import {Link} from "react-router-dom";
import {ThemeProvider} from "@mui/styles";
import scrollSyncIcon from './twoArrowsSync.svg';
import scrollAsyncIcon from './twoArrowsAsync.svg';
import scaleIcon from './scale.png'

export enum DifferenceBarPosition {
    RIGHT = "RIGHT",
    LEFT = "LEFT"
}

export enum FirstDocumentPosition {
    TOP = "TOP",
    LEFT = "LEFT"
}

export interface NavigationBarStateProps {
    login: string;
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    balance: number;
    header: string;
    isLoggedIn: boolean;
    isDetailedPage: boolean;
    scrollSync: boolean;
    isMagnifierActive: boolean;
    menuOpen: boolean;
    zoomCount: number;
    docFilterList: string[];
    activeAccountId: string;
    isUserInfo: boolean;
    canGetBalance: boolean;
}

export interface NavigationBarDispatchProps {
    onLogout: () => void;
    getPdf: () => void;
    getExtendParams: () => void;
    getBalance: () => void;
    setZoomCount: (zoom: number) => void;
    setUploadPopupOpen: (isOpen: boolean) => void;
    setScrollSync: (flag: boolean) => void;
    setMenuOpen: (flag: boolean) => void;
    setIsMagnifierActive: (flag: boolean) => void;
    updateDocFilterList: (list: string[]) => void;
    setExtendParams: () => void;
    setDifferenceBarPosition: (position: DifferenceBarPosition) => void;
    setFirstDocumentPosition: (position: FirstDocumentPosition) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
}

type NavigationBarProps = NavigationBarStateProps & NavigationBarDispatchProps;

const CustomSlider = styled(Slider)({
    color: '#F57B20',
});

const theme = createTheme({});

export const NavigationBar:FC<NavigationBarProps> = ({isDetailedPage, getExtendParams, setExtendParams, panelPosition, docPosition,
    activeAccountId, isUserInfo, canGetBalance, getBalance, setUploadPopupOpen, getPdf, docFilterList, updateDocFilterList, setIsMagnifierActive,
    isMagnifierActive, setZoomCount, setLeftScale, setRightScale, zoomCount, setFirstDocumentPosition, setDifferenceBarPosition, scrollSync, setScrollSync,
    isLoggedIn, login, onLogout, balance} ) => {
    const isPanelLeft = panelPosition === DifferenceBarPosition.LEFT || !panelPosition;
    const isDocumentTop = docPosition === FirstDocumentPosition.TOP || !docPosition;
    const scaleMenuRef = useRef();
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openScaleMenu, setOpenScaleMenu] = useState(false);
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [openStructureMenu, setOpenStructureMenu] = useState(false);

    useEffect((() => {
        if (!activeAccountId && isUserInfo) {
            window.location.href = process.env.REACT_APP_CORRECT_BASE_API + '/Integration/IntegrateWithComparerService?redirectToDocshow=True'
        }

        if(isDetailedPage){
            getExtendParams();
        }
    }), [activeAccountId, isUserInfo]);

    useLayoutEffect(() => {
        canGetBalance && getBalance();
    }, [])

    const STRUCTURE = [
        {
            title: "Панель изменений слева, документы горизонтально",
            differenceBarPosition: DifferenceBarPosition.LEFT,
            firstDocumentPosition: FirstDocumentPosition.TOP,
            structure: () => isPanelLeft && isDocumentTop,
            imgClassName: `${styles.documentPositionIconVertical}`,
            src: docTopIconActive,
        },
        {
            title: "Панель изменений слева, документы вертикально",
            differenceBarPosition: DifferenceBarPosition.LEFT,
            firstDocumentPosition: FirstDocumentPosition.LEFT,
            structure: () => isPanelLeft && !isDocumentTop,
            imgClassName: `${styles.documentPositionIcon} ${styles.mirror}`,
            src: docLeftIconActive,
        },
        {
            title: "Панель изменений справа, документы вертикально",
            differenceBarPosition: DifferenceBarPosition.RIGHT,
            firstDocumentPosition: FirstDocumentPosition.LEFT,
            structure: () => !isPanelLeft && !isDocumentTop,
            imgClassName: `${styles.documentPositionIcon}`,
            src: docLeftIconActive,
        },
        {
            title: "Панель изменений справа, документы горизонтально",
            differenceBarPosition: DifferenceBarPosition.RIGHT,
            firstDocumentPosition: FirstDocumentPosition.TOP,
            structure: () => !isPanelLeft && isDocumentTop,
            imgClassName: `${styles.documentPositionIconVertical} ${styles.mirror}`,
            src: docTopIconActive,
        }
    ]

    const [activeStructure, setActiveStructure] = useState(STRUCTURE[0]);

    const anchorComp = useRef(null);
    const anchorStruct = useRef(null);

    const handleFilterMenuClose = (event) => {
        setOpenFilterMenu(!openFilterMenu)
        if(!!openFilterMenu){
            setExtendParams();
        }
    }

    return <header className={styles.container}>
        <div className={styles.leftContainer}>
            {/*@ts-ignore*/}
            {!window.REACT_APP_HIDE_LOGO && <Link style={{textDecoration: "none"}} to={ROUTES.Home}>
                <div className={styles.iconContainer}>
                    <div className={styles.logo}>
                    </div>
                    <div className={styles.title}>
                        <div>Сравнение</div>
                        <div>документов</div>
                    </div>
                </div>
            </Link>}
            {/*@ts-ignore*/}
            {!window.REACT_APP_HIDE_UPLOAD_BUTTON && <Button
                variant={'contained'}
                onClick={() => {
                    setUploadPopupOpen(true);
                }}
                className={`${styles.link} 
                ${styles.linkActive}`}
            >
                +&nbsp;&nbsp;Загрузить файл
            </Button>}

            {isDetailedPage && <React.Fragment>
                <div
                    className={styles.iconsContainer}
                >
                    <div
                        className={styles.diffFilterContainer}
                    >
                        <IconButton
                            title={"Экспорт в PDF"}
                            size={"small"}
                            sx={{
                                height: '40px',
                                width: '40px',
                                color: '#000'
                            }}
                            onClick={() => {
                                getPdf()
                            }}
                        >
                            <img className={styles.exportToPdfButton} src={saveToPdf} alt={''}/>
                        </IconButton>
                        {/*@ts-ignore*/}
                        {!window.REACT_APP_HIDE_FILTER_BUTTON && <IconButton
                            title={'Настройки сравнения'}
                            ref={anchorComp}
                            size={"small"}
                            className={`${openFilterMenu ? styles.selectedItem : ""}`}
                        >
                            <Icon onClick={handleFilterMenuClose} icon="gala:settings" width="28" color={`#000`} height="28"/>
                            <Popper
                                anchorEl={anchorComp ? anchorComp.current : null}
                                className={styles.topPanelRightAvatarMenu}
                                open={openFilterMenu}
                                transition
                                disablePortal
                                sx={{top: 80,}}
                                placement={'bottom-start'}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom"
                                        }}
                                    >
                                        <Paper
                                        >
                                            <ClickAwayListener onClickAway={handleFilterMenuClose}>
                                                <MenuList
                                                    sx={{
                                                        marginTop: `10px`,
                                                        alignItems: 'start'
                                                    }}
                                                    id="menu-list-grow"
                                                >
                                                    {DOC_DIFFERENCE_FILTERS_LIST.map((elem, index) => {
                                                        const name = DOC_FILTERS[elem] ? DOC_FILTERS[elem] : "";
                                                        return <MenuItem
                                                            disabled={DOC_DIFFERENCE_FILTERS_DISABLED_LIST.includes(elem)}
                                                            sx={{
                                                                fontSize: '14px',
                                                                paddingTop: '0px',
                                                                paddingBottom: '0px',
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                sx={{
                                                                    fontSize: '14px'
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        sx={{
                                                                            height: '32px',
                                                                            width: '32px',
                                                                            color: `#F57B20`,
                                                                            '&.Mui-checked': {
                                                                                color: `#F57B20`,
                                                                            },
                                                                            fontSize: '14px',
                                                                        }}
                                                                        checked={docFilterList.includes(elem)}
                                                                        defaultChecked
                                                                        onChange={(e) => {
                                                                            if (docFilterList.includes(elem)) {
                                                                                updateDocFilterList(docFilterList.filter(el => el !== elem));
                                                                            } else {
                                                                                updateDocFilterList([...docFilterList, elem]);
                                                                            }

                                                                        }}
                                                                    />} label={<span
                                                                style={{fontSize: '14px'}}>{`${name}`}</span>}/>
                                                        </MenuItem>
                                                    })}
                                                    <div className={styles.divider}/>
                                                    <MenuItem
                                                        sx={{
                                                            fontSize: '14px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px',
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            sx={{
                                                                fontSize: '14px'
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        height: '32px',
                                                                        width: '32px',
                                                                        color: `#F57B20`,
                                                                        '&.Mui-checked': {
                                                                            color: `#F57B20`,
                                                                        },
                                                                        fontSize: '14px',
                                                                    }}
                                                                    checked={docFilterList.length === (DOC_DIFFERENCE_FILTERS_LIST.length)}
                                                                    defaultChecked
                                                                    onChange={(e) => {
                                                                        if (docFilterList.length === (DOC_DIFFERENCE_FILTERS_LIST.length)) {
                                                                            updateDocFilterList([...DEFAULT_DIFFERENCE_DOC_FILTERS_LIST]);
                                                                        } else {
                                                                            updateDocFilterList([...DOC_DIFFERENCE_FILTERS_LIST.filter(elem => !DOC_DIFFERENCE_FILTERS_DISABLED_LIST.includes(elem))]);
                                                                        }
                                                                    }}
                                                                />} label={<span
                                                            style={{fontSize: '14px'}}>{`Включить/отключить все фильтры`}</span>}/>
                                                    </MenuItem>
                                                    <div
                                                        className={styles.setButtonContainer}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                updateDocFilterList([...DEFAULT_DIFFERENCE_DOC_FILTERS_LIST]);
                                                            }}
                                                            className={styles.setDefaultButton}
                                                        >
                                                            Фильтры по умолчанию
                                                        </Button>
                                                    </div>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </IconButton>}
                    </div>
                    <IconButton
                        sx={{
                            height: '38px',
                            width: '38px',
                            color: '#000'
                        }}
                        title={'увеличительное стекло'}
                        onClick={(e) => {
                            setIsMagnifierActive(!isMagnifierActive)
                        }}
                        size={"small"}
                        className={` ${styles.buttonContainer} ${isMagnifierActive ? styles.selectedItem : ""}`}
                    >
                        <Icon style={{
                            width: '34 !important',
                            height: '34 !important',
                            color: `#000`
                        }} icon="radix-icons:magnifying-glass" width="34" height="34"/>
                    </IconButton>
                    <IconButton
                        ref={scaleMenuRef}
                        onClick={() => setOpenScaleMenu(true)}
                        className={` ${styles.buttonContainer}`}
                        size={"small"}
                    >
                        <span
                            className={styles.diffFilterContainer}
                            title={'Масштаб'}
                        >
                            <img width={'25px'} height={'25px'} style={{objectFit: 'contain'}} src={scaleIcon} alt={''}/>
                        </span>
                    </IconButton>
                    <Popper
                        anchorEl={scaleMenuRef ? scaleMenuRef.current : null}
                        className={styles.topPanelRightAvatarMenu}
                        open={openScaleMenu}
                        transition
                        placement={'bottom'}
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper
                                    sx={{
                                        height: '216px'
                                    }}
                                >
                                    <ClickAwayListener onClickAway={
                                        (event) => {
                                            setOpenScaleMenu(false);
                                        }
                                    }>
                                        <CustomSlider
                                            onChange={(e, value) => {
                                                setZoomCount(value as number)
                                                setLeftScale((value as number) / 100)
                                                setRightScale((value as number) / 100)
                                            }}
                                            sx={{
                                                '& input[type="range"]': {
                                                    WebkitAppearance: 'slider-vertical',
                                                },
                                                height: 200,
                                                marginTop: '8px',
                                                marginBottom: '8px',
                                            }}
                                            orientation="vertical"
                                            step={10}
                                            min={10}
                                            max={200}
                                            value={zoomCount}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={() => `${zoomCount}%`}
                                            valueLabelFormat={() => `${zoomCount}%`}
                                            aria-labelledby="non-linear-slider"
                                        />
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <div
                        className={styles.diffFilterContainer}
                    >
                        <IconButton
                            title={activeStructure.title}
                            ref={anchorStruct}
                            className={`${openStructureMenu ? styles.selectedItem : ""}`}
                        >
                            <IconButton
                                size={"small"}
                                sx={{
                                    height: '28px',
                                    width: '28px',
                                    color: '#000'
                                }}
                                onClick={(event) => {
                                    setOpenStructureMenu(!openStructureMenu)
                                }}
                            >
                                <img className={activeStructure.imgClassName} width={34} height={32}
                                     src={activeStructure.src} alt={''}/>
                            </IconButton>
                            <Popper
                                anchorEl={anchorStruct ? anchorStruct.current : null}
                                className={styles.topPanelRightAvatarMenu}
                                open={openStructureMenu}
                                transition
                                sx={{top: 80,}}
                                placement={'bottom-start'}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom"
                                        }}
                                    >
                                        <Paper
                                        >
                                            <ClickAwayListener onClickAway={
                                                (event) => {
                                                    setOpenStructureMenu(false);
                                                }
                                            }>
                                                <MenuList
                                                    sx={{
                                                        marginTop: `10px`,
                                                        paddingTop: '0px',
                                                        alignItems: 'start'
                                                    }}
                                                    id="menu-list-grow"
                                                >
                                                    {STRUCTURE.map(elem =>
                                                        <MenuItem
                                                            className={elem.structure() && styles.selectedItem}
                                                            sx={{
                                                                fontSize: '14px',
                                                                paddingBottom: '0px',
                                                                paddingLeft: '6px',
                                                                paddingTop: '4px'
                                                            }}
                                                            onClick={() => {
                                                                setFirstDocumentPosition(elem.firstDocumentPosition);
                                                                setDifferenceBarPosition(elem.differenceBarPosition);
                                                                setOpenStructureMenu(false)
                                                                setActiveStructure(elem)
                                                                setExtendParams();
                                                            }}
                                                        >
                                                            <IconButton
                                                                size={"small"}
                                                                sx={{
                                                                    height: '28px',
                                                                    width: '28px',
                                                                    color: '#000',
                                                                    marginRight: '10px',
                                                                    paddingLeft: '8px',
                                                                    paddingTop: '4px'
                                                                }}
                                                                className={`${elem.structure() && styles.selectedItem}`}
                                                            >
                                                                <img className={elem.imgClassName} width={34}
                                                                     height={32}
                                                                     src={elem.src} alt={''}/>
                                                            </IconButton>
                                                            {elem.title}
                                                        </MenuItem>)}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </IconButton>
                    </div>
                    {!scrollSync && <IconButton
                        onClick={(event) => {
                            setScrollSync(true)
                        }}
                        sx={{
                            width: '40px',
                            height: '40px'
                        }}
                    >
                        <img title={'Прокрутка не синхронизирована'} style={{objectFit: 'contain'}} width={38}
                             height={30}
                             src={scrollAsyncIcon} alt={''}/>
                    </IconButton>}
                    {scrollSync && <IconButton
                        onClick={(event) => {
                            setScrollSync(false)
                        }}
                        sx={{
                            width: '40px',
                            height: '40px'
                        }}
                    >
                        <img title={'Прокрутка синхронизирована'} style={{objectFit: 'contain'}} width={38} height={30}
                             src={scrollSyncIcon} alt={''}/>
                    </IconButton>}
                </div>

            </React.Fragment>}
        </div>
        <div className={styles.topPanelRight}>
            {/*@ts-ignore*/}
            {!window.REACT_APP_HIDE_BALANCE && <div className={styles.row}>
                {isLoggedIn ? <div className={styles.itemColumn}>
                        <div
                            onClick={() => setOpenUserMenu(!openUserMenu)}
                            className={styles.topPanelRightAvatarCont}
                        >
                            <span className={styles.topPanelRightAvatarUserName}>{login}</span>
                            <span className={styles.arrowDown}/>
                        </div>
                        <ThemeProvider theme={theme}>
                            <Popper
                                className={styles.topPanelRightAvatarMenu}
                                open={openUserMenu}
                                transition
                                disablePortal
                                style={{top: 60, left: "auto", right: 50}}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom"
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => setOpenUserMenu(false)}>
                                                <MenuList
                                                    id="menu-list-grow"
                                                    onKeyDown={() => setOpenUserMenu(!openUserMenu)}
                                                >
                                                    <MenuItem
                                                        onClick={() => setOpenUserMenu(false)}
                                                    >
                                                        <a
                                                            target={'_blank'}
                                                            href={'https://lkk.correct.su/Billing/Tariffs'}
                                                        >
                                                            Личный кабинет</a>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onLogout()
                                                            setOpenUserMenu(false)
                                                        }}>
                                                        Выход
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </ThemeProvider>
                        <div className={styles.row}>
                            <div className={styles.rowItem}>
                                <div className={styles.text}>
                                    Баланс:
                                </div>
                                <div className={styles.textBalance}>
                                    {balance} страниц
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div
                        className={styles.rowItem}
                    >
                        <a
                            href={`${process.env.REACT_APP_CORRECT_LK_LOGIN_LINK}`}
                        >
                            <Button
                                variant={'contained'}
                                className={styles.buttonAction}
                                size={"small"}
                            >
                                Войти
                            </Button>
                        </a>
                        <a href={`${process.env.REACT_APP_CORRECT_LK_REGISTRATION_LINK}`}>
                            <Button
                                variant={'contained'}
                                className={styles.buttonCancel}
                                size={"small"}
                            >
                                Регистрация
                            </Button>
                        </a>
                    </div>}
            </div>}

        </div>
    </header>

}
