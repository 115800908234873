import React, {CSSProperties} from 'react';
import MaterialTable, {Column} from "material-table";
import {createTheme, Paper} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import {materialTableLocalizationRu} from "./materialTableLocalization";
import {TablePagination} from '@material-ui/core';
import {tableIcons} from "./tableIcons";
import styles from "./Table.module.scss"

export interface ITableProps {
    pageSize: number;
    initialPage?: number;
    columns: Column<Object>[];
    onChangeListPage?: (pageNumber: number) => void;
    data: Object[];
    onRowClick?: (event: any, rowData: any) => void;
}

export function Table(props: ITableProps) {
    const {columns, initialPage, pageSize, data, onRowClick} = props;
    const defaultMaterialTheme = createTheme({
    });
    const prepareColumns = columns.map((col, index) => ({
        ...col,
        cellStyle: col.field !== "status"
            ? {} as CSSProperties
            : {width: '5%',} as CSSProperties,
        headerStyle: col.field !== "status"
            ? {
                zIndex: 0,
                backgroundColor: '#FFF',
                fontFamily: "'Lato', sans-serif",
                fontSize: '18px',
                fontWeight: '400 !important',
                lineHeight: '18px',
                letterSpacing: '0em',
                textAlign: 'left',
                width: "auto !important",
            } as CSSProperties
            : {
                textAlign: 'left',
                alignItems: 'left !important',
                zIndex: 0,
                backgroundColor: '#FFF',
                fontFamily: "'Lato', sans-serif",
                fontSize: '18px',
                fontWeight: '400 !important',
                lineHeight: '18px',
                letterSpacing: '0em',

} as CSSProperties
    }));

    const changePage = e => {
        if (props.onChangeListPage) {
            props.onChangeListPage(e)
        }
    }
    const updateMaterialTableKey = `MaterialTable_UPDATE_KEY_${Math.random()}`;

    return <div className={styles.tableContainer}>
        <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
                components={{
                    Container: props => <Paper {...props} elevation={0}/>,
                    Pagination: props1 => <TablePagination {...props1}/>
                }}
                key={updateMaterialTableKey}
                columns={prepareColumns}
                data={data}
                localization={materialTableLocalizationRu}
                icons={tableIcons}
                onRowClick={onRowClick}
                options={{
                    rowStyle: {
                        zIndex: '0 !important',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: '16px',
                        fontWeight: '300 !important',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                    },
                    initialPage: initialPage,
                    pageSizeOptions: [],
                    pageSize,
                    toolbar: false,
                    actionsColumnIndex: 7,
                    sorting: true,
                    thirdSortClick: true,
                    emptyRowsWhenPaging: true,
                    draggable: false,
                    search: false,
                    showTitle: false,
                    searchFieldAlignment: "left",

                }}
                onChangePage={changePage}
            />
        </ThemeProvider>
    </div>
}
