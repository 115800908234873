import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {setComment, setCommentPopupOpen} from "../../ducks/upload";
import {selectComment, selectCommentPopupOpen} from "../../selectors/upload";
import {CommentPopup, CommentPopupDispatchProps, CommentPopupStateProps} from "./CommentPopup";
import {updateComment} from "../../ducks/montitoring";
import {routeTo} from "../../ducks/baseCRUD";

const mapStateToProps: MapStateToProps<CommentPopupStateProps,
    {},
    State> = state => {
    const comment = selectComment(state);
    const isOpen = selectCommentPopupOpen(state);

    return {
        comment,
        isOpen,
    };
};

const dispatchProps: MapDispatchToProps<CommentPopupDispatchProps, {}> = {
    setComment,
    setCommentPopupOpen,
    updateComment: (status?: number) => {
        return updateComment(status);
    },
    routeTo: (route, id?) => {
        return routeTo(route, id)
    },
};

export const CommentPopupConnected = connect(
    mapStateToProps,
    dispatchProps
)(CommentPopup);
