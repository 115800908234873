import React, {useEffect, useMemo, useState} from "react";
import {ICanvasDifferenceElement} from "../components/differenceDocument/DifferenceDocument";

export function useMouse(ref: React.RefObject<HTMLElement>, pageZoom: number, rotate: number = 0) {
    const [mouse, setMouse] = useState<{ x: number; y: number, isActive: boolean }>({x: 0, y: 0, isActive: false});
    useEffect(() => {
        if (ref && ref.current) {
            const handleMouseMove = (e: MouseEvent) => {
                // get mouse position relative to ref
                const rect = ref.current?.getBoundingClientRect();
                if (rect) {
                    setMouse({
                        x: e.clientX - rect.left,
                        y: e.clientY - rect.top,
                        isActive: true,
                    });
                }
            };
            const handleMouseOut = (e: MouseEvent) => {
                setMouse({
                    x: 0,
                    y: 0,
                    isActive: false,
                });
            }
            if (ref && ref.current) {
                ref.current.addEventListener("mousemove", handleMouseMove);
                ref.current.addEventListener("mouseleave", handleMouseOut);
            }

            return () => {
                if (ref && ref.current) {
                    ref.current!.removeEventListener("mousemove", handleMouseMove);
                    ref.current!.removeEventListener("mouseleave", handleMouseOut);
                }
            };
        }
    });
    return mouse;
}

export function useMouseOverZoom(
    source: React.RefObject<HTMLImageElement>,
    target: React.RefObject<HTMLCanvasElement>,
    targetOrigin: React.RefObject<HTMLCanvasElement>,
    cursor: React.RefObject<HTMLElement>,
    paper: React.RefObject<HTMLElement>,
    pageZoom: number,
    diffsArray: ICanvasDifferenceElement[],
    foolHeight: boolean,
    radius = 25,
    rotate: number = 0
) {
    // Capture Mouse position
    const {x, y, isActive} = useMouse(paper, pageZoom, rotate);
    // Compute the part of the image to zoom based on mouse position
    const zoomBounds = useMemo(() => {
        return {
            left: rotate === 0 ? x :

                (rotate === -90 || rotate === 270) ?
                    source?.current?.width - y :

                    (rotate === -180 || rotate === 180) ?
                        source?.current?.width - x :

                        /*(rotate == -270 || rotate == 90)*/
                        y,
            top: rotate === 0 ? y :

                (rotate === -90 || rotate === 270) ? x :

                    (rotate === -180 || rotate === 180) ? source?.current?.height - y :

                        /*(rotate == -270 || rotate == 90)*/
                        source?.current?.height - x,
            width: radius * 20,
            height: radius * 20,
        }
    }, [x, y, rotate]);
    // move the cursor to the mouse position
    useEffect(() => {
        if (cursor.current) {
            const {left, top, width, height} = zoomBounds;
            cursor.current.style.left = `${left}px`;
            cursor.current.style.top = `${top}px`;
            cursor.current.style.width = `${width}px`;
            cursor.current.style.height = `${height}px`;
            cursor.current.style.display = isActive ? "block" : "none";
        }
    }, [zoomBounds, isActive]);
    // draw the zoomed image on the canvas
    useEffect(() => {
        if (source.current && target.current && targetOrigin.current) {
            const ctx = target.current.getContext("2d");
            const ctxOrigin = targetOrigin.current.getContext("2d");
            const zoomCount = (foolHeight ? 3 : 1.5) / ((pageZoom && pageZoom <= 100) ? pageZoom * 0.01 : 1);
            if (ctx && ctxOrigin) {
                if (isActive) {
                    const {left, top} = zoomBounds;

                    targetOrigin.current.width = source.current.width * zoomCount;
                    targetOrigin.current.height = source.current.height * zoomCount;
                    target.current.width = 300;
                    target.current.height = 300;
                    ctxOrigin.drawImage(source.current, 0, 0, targetOrigin.current.clientWidth * zoomCount, targetOrigin.current.clientHeight * zoomCount)
                    diffsArray.forEach((diff) => {
                        ctxOrigin.strokeStyle = `${diff.color}`;
                        ctxOrigin.strokeRect(diff.x * zoomCount, diff.y * zoomCount, diff.width * zoomCount, diff.height * zoomCount);
                        if (diff.isDetailed) {
                            ctxOrigin.fillStyle = `${diff.colorFill}`;
                            ctxOrigin.fillRect(diff.x * zoomCount, diff.y * zoomCount, diff.width * zoomCount, diff.height * zoomCount);
                        }
                    })
                    ctx.scale(15, 15)
                    ctx.putImageData(
                        ctxOrigin.getImageData(
                            (left * zoomCount) - (target.current.width / 2),
                            (top * zoomCount) - (target.current.height / 2),
                            (target.current.width) * 2,
                            (target.current.height) * 2
                        ),
                        0,
                        0,
                    );
                    // ctx.drawImage(
                    //     targetOrigin.current,
                    //     left * imageRatio,
                    //     top * imageRatio,
                    //     width * imageRatio,
                    //     height * imageRatio,
                    //     0,
                    //     0,
                    //     target.current.width / ((pageZoom  && pageZoom <= 100) ? pageZoom * 0.01 : 1),
                    //     target.current.height / ((pageZoom  && pageZoom <= 100) ? pageZoom * 0.01 : 1)
                    // );
                } else {
                    // clear canvas
                    ctx.clearRect(0, 0, target.current.width, target.current.height);
                    ctxOrigin.clearRect(0, 0, targetOrigin.current.width, targetOrigin.current.height);
                }
            }
        }
    }, [zoomBounds, isActive])
}
