import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {
    IMonitoringPageDispatchProps,
    IMonitoringPageStateProps,
    IRecognitionActTable,
    MonitoringPage
} from "./MonitoringPage";
import {
    getSortType,
    getStoragePage,
    selectListPage,
    selectMonitoringList,
    selectSearchOptions,
    selectSortType
} from "../../selectors/monitoring";
import {
    clearMonitoringList,
    getCompareList,
    MONITORING_STATUSES,
    setListPage,
    setSearchOptions,
    setSortType
} from "../../ducks/montitoring";
import moment from "moment";
import {routeTo} from "../../ducks/baseCRUD";
import {IMonitoringItem} from "../../api/monitoringApi";
import {selectUploadPopupIsOpen} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<IMonitoringPageStateProps, {}, State> = state => {
    const props: IMonitoringPageStateProps = {
        list: [],
        isUploadPopupOpen: false,
        options: [],
        sort: "",
        listPage: 0,
    };
    const searchOptions = selectSearchOptions(state);
    const listFromState: IMonitoringItem[] = selectMonitoringList(state);
    const isOpen = selectUploadPopupIsOpen(state);
    const storageSort = getSortType();
    const stateSort = selectSortType(state);
    const storagePage = getStoragePage();
    const statePage = selectListPage(state);
    let list: IRecognitionActTable[] = [];

    props.options = searchOptions;

    if (isOpen) {
        props.isUploadPopupOpen = isOpen;
    }

    try {
        if (listFromState.length > 0) {
            list = listFromState.map((elem) => {
                try {
                    const status = MONITORING_STATUSES[elem.status.id];
                    return {
                        id: elem.id,
                        upload_date: moment(elem.createdAt).format("DD.MM.YYYY HH:mm:ss"),
                        status: status ? status : "",
                        compareDate: moment(elem.compareDate).format("DD.MM.YYYY HH:mm:ss"),
                        key: elem.key,
                        firstDocument: elem.images && elem.images[0] ? elem.images[0].name : "",
                        secondDocument: elem.images && elem.images[1] ? elem.images[1].name : "",
                        statusId: elem.status ? elem.status.id : 0,
                        comment: elem.comment ?  elem.comment : "",
                    }
                } catch (e) {
                    return undefined;
                }
            }).filter(elem => elem);
        }
    } catch (e) {
        console.log("e", e)
    }

    props.sort = stateSort !== storageSort ? storageSort : stateSort;
    if(list.length > 0){
        props.listPage = statePage !== storagePage ? storagePage : statePage;
        props.list = list;
    }
    return {...props}
};

const dispatchProps: MapDispatchToProps<IMonitoringPageDispatchProps, {}> = {
    getRecognitionActs: getCompareList,
    routeTo: (route, id?) => {
        return routeTo(route, id)
    },
    clearMonitoringList,
    setSearchOptions,
    setSortType,
    setListPage,
};

export const MonitoringPageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(MonitoringPage);
