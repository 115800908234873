//@ts-ignore
import wretch, {Wretch} from "wretch";
import {getToken} from "../helper/other";
import {getStoreUtils} from "../store/utils";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";
import {selectPath, selectUrlId} from "../selectors/other";

export const AUTHORIZATION_HEADER = 'X-AUTH';
export const AUTHORIZATION_PREFIX = 'Bearer ';

export const ENDPOINTS = {
    auth: "/user/auth",
    users: "/user",
    balance: "/balance",
}

const getBase = (): Wretch => {
    const state = getStoreUtils().getState();
    //@ts-ignore
    const baseUrl = window.REACT_APP_BASE_CONFIG_API_URL ? window.REACT_APP_BASE_CONFIG_API_URL : process.env.REACT_APP_BASE_API_URL
    //@ts-ignore
    const headers = {"Recognition-Product": 'Comparing'}
    //@ts-ignore
    if(window.REACT_APP_BASE_CONFIG_TOKEN){
        //@ts-ignore
        headers[AUTHORIZATION_HEADER] =`${AUTHORIZATION_PREFIX}${window.REACT_APP_BASE_CONFIG_TOKEN}`
    } else if(process.env.REACT_APP_TEST_TOKEN){
        headers[AUTHORIZATION_HEADER] =`${AUTHORIZATION_PREFIX}${process.env.REACT_APP_TEST_TOKEN}`
    }
    //@ts-ignore
    if(window.REACT_APP_SSO_AUTH) {
        const token = getToken('jwt-token-local');
        const path = selectPath(state, false);
        const pathProcessed = selectPath(state, true);
        const urlId = selectUrlId(state);
        if(token){
            headers[AUTHORIZATION_HEADER] =`${AUTHORIZATION_PREFIX}${token}`;
        }
        if (pathProcessed === ROUTES.Doc && urlId)  {
            /*let url = new URL(window.location.origin);
            url.searchParams.append('key', urlId as string);
            window.location.href = url.toString();*/
        }else if(! [ROUTES.Home].includes(path) && !token) window.location.pathname = "/";
    }
    //@ts-ignore
    return wretch(baseUrl)
        .options({ credentials: "include"})
        .headers({...headers})
        .catcher(401, (error, originalRequest) => {
            getStoreUtils().dispatch(push(`${ROUTES.Auth}`))
        })
        .catcher(403, async (error, originalRequest) => {
            getStoreUtils().dispatch(push(`${ROUTES.Auth}`))
        });
};

export type UrlPart = string | number;

export const getEndpoint = (
    endpoint: string,
    urlPart?: UrlPart,
) => {
    const url = "/api" + endpoint + (urlPart !== undefined ? `${urlPart}` : "");
    return getBase().url(url);
};
