import {State} from "../store/rootReducers";
import {ROUTES} from "../app/Routes";
import {DifferenceType} from "../api/monitoringApi";

export function selectHeader(state: State) {
    const path = state.router.location.pathname;
    let header = "";

    switch (path) {
        // case ROUTES.Monitoring:
        // case "/": {
        //     header = "Список актов сверки"
        //     break;
        // }
        // case "/auth": {
        //     header = "Вход"
        //     break;
        // }
        case ROUTES.Doc: {
            header = "Сравнение актов сверки"
            break;
        }
        // case ROUTES.Info: {
        //     header = "О сервисе"
        //     break;
        // }
    }

    return header;
}

export function selectUrlId(state: State): string | number | undefined {
    const pathname = state.router.location.pathname;
    const elements = pathname.split('/');
    const rawId = elements[elements.length - 1];
    return rawId ? rawId : undefined;
}

export function selectPath(state: State, processed: boolean = true) {
    const pathname = state.router.location.pathname;
    const elements = pathname.split("/");

    let path;
    const lastElement = elements[elements.length - 1];
    if (processed && !isNaN(parseInt(lastElement))) { //is numeric
        elements.splice(elements.length - 1, 1);
        path = elements.join('/');
    } else if (processed && isNaN(parseInt(lastElement))) {
        elements.splice(elements.length - 1, 1);
        path = elements.join('/');
    } else {
        path = pathname;
    }

    return path;
}

export function getDifferenceData(difference: DifferenceType): { name: string, color: string, colorValue: string, colorFill: string } {
    let diffName = '';
    let color = '';
    let colorValue = '';
    let colorFill = '';

    switch (difference) {
        case 'Added':
            diffName = 'Добавлено';
            color = 'colorAdded';
            colorValue = 'green';
            colorFill = 'rgba(0, 128, 0, 0.2)'
            break;
        case 'Changed':
            diffName = 'Изменено';
            color = 'colorChanged';
            colorValue = '#e020f5';
            colorFill = 'rgba(224, 32, 245, 0.2)'
            break;
        case 'Deleted':
            diffName = 'Удалено';
            color = 'colorDelete';
            colorValue = 'red';
            colorFill = 'rgba(255, 0, 0, 0.2)'
            break;
        case 'AddedPages':
            diffName = 'Добавлены страницы';
            color = 'colorAdded';
            colorValue = 'green';
            colorFill = 'rgba(0, 128, 0, 0.2)'
            break;
        case 'ChangedPages':
            diffName = 'Изменены страницы';
            color = 'colorChanged';
            colorValue = '#e020f5';
            colorFill = 'rgba(224, 32, 245, 0.2)'
            break;
        case 'DeletedPages':
            diffName = 'Удалены страницы';
            color = 'colorDelete';
            colorValue = 'red';
            colorFill = 'rgba(255, 0, 0, 0.2)'
            break;
    }
    return {
        color: color,
        name: diffName,
        colorValue: colorValue,
        colorFill: colorFill,
    };
}