import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {AuthPage, IAuthPageDispatchProps, IAuthPageStateProps} from "./AuthPage";

const mapStateToProps: MapStateToProps<IAuthPageStateProps, {}, State> = state => {
    return {

    }
};

const dispatchProps: MapDispatchToProps<IAuthPageDispatchProps, {}> = {
};

export const AuthPageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(AuthPage);